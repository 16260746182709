import React, { useState, useEffect } from "react";
// import Pagination from "@mui/material/Pagination";
import Pagination from "../../SubComponents/Pagination/Pagination";
// import Stack from "@mui/material/Stack";
// import rows from "../../../achieversInfoData.js";
// import EditIcon from "../../../assets/images/EditIcon.svg";
import ModalComponent from "../../Modal/Modal";
import "./achieversInfo.css";
import Box from "@mui/material/Box";
import { Formik, Form, Field } from "formik";
import Axios from "axios";
import { BASE_URL } from "../../config/Api.js";
import { toast, ToastContainer } from "react-toastify";
import ReactLoading from "react-loading";
import { MultiSelect } from "react-multi-select-component";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import NavbarInfo from "./Header/NavbarInfo";
import { UPLOAD_URL } from "../../config/Api.js";
import configuration from "../../config/Config";
import s3 from "../../config/DigitalOcean";

const initialValues = {
  name: "",
  title: "",
  tileCaption: "",
  aboutLink: "",
  achieverCode: "",
  introVideoURL: "",
  introVideoId: "",
  subtitle: [],
  country: -1,
  duration: 0,
};
const thead_column = [
  "Edit",
  "Enable",
  "Country",
  "Achiever Code",
  "Name",
  "Title",
  "Tile Caption",
  "Course",
  "Notes",
  "Talks",
  "Duration",
];
var tempTableData = [];
var tempLanguageArray = [];
var subtitleJson = [];
var rowData;
var achieverName = "";
var title = "";
var tileCaption = "";
var aboutLink = "";
var achieverCode = "";
// var introVideoURL = "";
// var introVideoId = "";
var countryId = -1;
// var numberOfPages = 1;
var FilePathThumbnail;
var sourceFilePathThumbnail = "";
// var duration = "";
var oneAchieverData;
// const countries = ["Country", "India", "Nepal", "Shrilanka"];

function AchieversInfo() {
  const [show, setShow] = useState(false);
  const [editValue, setEditValue] = useState(false);
  const [rowValues, setRowValues] = useState({
    name: "",
    tileCaption: "",
    country: -1,
    achieverID: -1,
    achieverCode: "",
    aboutLink: "",
  });
  const [tableData, setTableData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [allLanguages, setAllLanguages] = useState([]);
  const [userDetails, setUserDetails] = useState(false);
  const [status, setStatus] = useState("All");
  const [startUpload, setStartUpload] = useState(false);
  const [selected, setSelected] = useState([]);
  const [popUp, setPopUp] = useState(false);
  const [deleteRow, setDeleteRow] = useState(false);
  const [notFilled, setNotFilled] = useState(false);
  const [navCountryId, setNavCountryId] = useState("-1");
  const [navSearch, setNavSearch] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [offSet, setOffSet] = useState(0);
  const [numberOfRows, setNumberOfRows] = useState(0);
  const [noData, setNoData] = useState(false);
  const [attThumbnailFile, setAttThumbnailFile] = useState("");
  const [uploadThumbnail, setUploadThumbnail] = useState(false);
  const [uploadedThumbnail, setUploadedThumbnail] = useState(false);
  const [pic, setPic] = useState("");

  const userId = window.localStorage.getItem("userId");
  // const userName = window.localStorage.getItem("userName");
  const token = window.localStorage.getItem("token");
  const Auth = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  useEffect(() => {
    setNoData(false);
    async function handlePagination() {
      console.log(offSet);
      try {
        const getTable = await Axios.get(
          `${BASE_URL}/achievers?limit=10&offset=${offSet}&country_id=${navCountryId}&search_text=${navSearch}`,
          Auth
        );
        console.log(getTable.data.data);
        setTableData(getTable.data.data);
        setStartUpload(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response);
          toast.error(err.response.data.message);
        }
        setStartUpload(false);
      }
    }
    async function handlePaginationEnabled() {
      console.log(offSet);
      try {
        const getTable = await Axios.get(
          `${BASE_URL}/achievers?limit=10&offset=${offSet}&status=true&country_id=${navCountryId}&search_text=${navSearch}`,
          Auth
        );
        console.log(getTable.data.data);
        setTableData(getTable.data.data);
        setStartUpload(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response);
          toast.error(err.response.data.message);
        }
        setStartUpload(false);
      }
    }

    async function handlePaginationDisabled() {
      console.log(offSet);
      try {
        const getTable = await Axios.get(
          `${BASE_URL}/achievers?limit=10&offset=${offSet}&status=false&country_id=${navCountryId}&search_text=${navSearch}`,
          Auth
        );
        console.log(getTable.data.data);
        setTableData(getTable.data.data);
        setStartUpload(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response);
          toast.error(err.response.data.message);
        }
        setStartUpload(false);
      }
    }

    if (status === "All") {
      handlePagination();
    }
    if (status === "Enabled") {
      handlePaginationEnabled();
    }
    if (status === "Disabled") {
      handlePaginationDisabled();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offSet]);

  const handleClose = (e) => {
    // e.preventDefault();
    achieverName = "";
    title = "";
    tileCaption = "";
    aboutLink = "";
    achieverCode = "";
    // introVideoURL = "";
    // introVideoId = "";
    countryId = -1;
    tempLanguageArray = [];
    subtitleJson = [];
    sourceFilePathThumbnail = "";
    // duration = "";
    setShow(false);
    setEditValue(false);
    setStartUpload(false);
    setSelected([]);
    setRowValues({});
    setNotFilled(false);
    setPic("");
    setAttThumbnailFile("");
    setUploadThumbnail(false);
    setUploadedThumbnail(false);
  };
  const handleShow = async () => {
    setStartUpload(true);
    setShow(true);
    try {
      const getAllLanguages = await Axios.get(
        `${BASE_URL}/master_data/languages`,
        Auth
      );
      console.log(getAllLanguages.data.data);
      tempLanguageArray = getAllLanguages.data.data;
      tempLanguageArray = tempLanguageArray.map((language) => {
        return { value: language.code, label: language.name };
      });
      setAllLanguages(tempLanguageArray);
      setStartUpload(false);
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setStartUpload(false);
    }
  };

  async function onSubmit(value) {
    console.log(value);
    console.log(subtitleJson);
    setStartUpload(true);
    if (
      value.name === "" ||
      value.country === -1 ||
      value.title === "" ||
      value.tileCaption === "" ||
      value.aboutLink === "" ||
      value.achieverCode === "" ||
      sourceFilePathThumbnail === ""
      // value.duration === "" ||
      // value.introVideoURL === "" ||
      // value.introVideoId === "" ||
      // selected === []
    ) {
      toast.error("Please enter details in all the fields");
      setStartUpload(false);
      setNotFilled(true);
    } else {
      if (editValue) {
        subtitleJson = selected.map((language) => {
          return { name: language.label, code: language.value };
        });
        subtitleJson = JSON.stringify(subtitleJson);
        const dataEdit = {
          full_name: value.name,
          country_id: value.country,
          tile_caption: value.tileCaption,
          about_html: value.aboutLink,
          achiever_code: value.achieverCode,
          achiever_intro_video_url: value.introVideoURL,
          achiever_intro_video_id: value.introVideoId,
          subtitle_languages_json: subtitleJson,
          is_enabled: true,
          duration_in_secs: value.duration,
          thumbnail_url: sourceFilePathThumbnail,
          title: value.title,
        };
        try {
          const newAchiever = await Axios.put(
            `${BASE_URL}/achievers/${rowValues.achieverID}`,
            dataEdit,
            Auth
          );
          console.log(newAchiever);
          toast.success("Successfully Edited Achiever Info");
          setStartUpload(false);
          handleClose();
          fetchTable();
        } catch (error) {
          if (error.response && error.response.data) {
            console.log(error.response.data.message);
            toast.error(error.response.data.message);
          }
          setStartUpload(false);
        }
      } else {
        subtitleJson = selected.map((language) => {
          return { name: language.label, code: language.value };
        });
        subtitleJson = JSON.stringify(subtitleJson);
        const data = {
          full_name: value.name,
          country_id: value.country,
          tile_caption: value.tileCaption,
          about_html: value.aboutLink,
          achiever_code: value.achieverCode,
          achiever_intro_video_url: value.introVideoURL,
          achiever_intro_video_id: value.introVideoId,
          subtitle_languages_json: subtitleJson,
          duration_in_secs: value.duration,
          thumbnail_url: sourceFilePathThumbnail,
          title: value.title,
        };
        console.log(data);
        try {
          const newAchiever = await Axios.post(
            `${BASE_URL}/achievers/`,
            data,
            Auth
          );
          console.log(newAchiever);
          toast.success("Successfully Added New Achiever");
          setStartUpload(false);
          handleClose();
          fetchTable();
        } catch (error) {
          if (error.response && error.response.data) {
            console.log(error.response.data.message);
            toast.error(error.response.data.message);
          }
          setStartUpload(false);
        }
      }
    }
  }

  async function handleCheckbox(e, row) {
    setStartUpload(true);
    var check = e.target.checked;
    console.log(check);
    tempTableData = tableData.map((object) => {
      return object.achiever_id === row.achiever_id
        ? { ...object, is_enabled: check }
        : { ...object };
    });
    subtitleJson = JSON.stringify(row.subtitle_languages_json);
    setTableData(tempTableData);
    const dateEnbleEdit = {
      full_name: row.achiever_full_name,
      country_id: row.country_id,
      tile_caption: row.tile_caption,
      about_html: row.about_html_url,
      achiever_code: row.achiever_code,
      achiever_intro_video_url: row.achiever_intro_video_url,
      achiever_intro_video_id: row.achiever_intro_video_id,
      subtitle_languages_json: subtitleJson,
      is_enabled: check,
      duration_in_secs: row.duration,
      thumbnailURL: row.thumbnail_url,
      title: row.title,
    };
    try {
      const newAchiever = await Axios.put(
        `${BASE_URL}/achievers/${row.achiever_id}`,
        dateEnbleEdit,
        Auth
      );
      console.log(newAchiever);
      toast.success("Successfully Edited Achiever Info");
      setStartUpload(false);
      fetchTable();
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setStartUpload(false);
      fetchTable();
    }
  }

  async function handleEdit(row) {
    console.log(row);
    setStartUpload(true);
    setEditValue(true);
    try {
      const oneAchiever = await Axios.get(
        `${BASE_URL}/achievers/${row.achiever_id}`,
        Auth
      );
      console.log(oneAchiever.data.data);
      oneAchieverData = oneAchiever.data.data;
      sourceFilePathThumbnail = oneAchieverData.thumbnail_url;
      var reqLanguageType = Array.isArray(
        oneAchieverData.subtitle_languages_json
      )
        ? oneAchieverData.subtitle_languages_json.map((language) => {
            return {
              value: language.code,
              label: language.name,
            };
          })
        : [];
      console.log(reqLanguageType);
      setSelected(reqLanguageType);
      setRowValues((prevState) => ({
        ...prevState,
        name: oneAchieverData.achiever_full_name,
        tileCaption: oneAchieverData.tile_caption,
        country: oneAchieverData.country_id,
        achieverID: oneAchieverData.achiever_id,
        achieverCode: oneAchieverData.achiever_code,
        aboutLink: oneAchieverData.about_html_url,
        introVideoURL: oneAchieverData.achiever_intro_video_url,
        introVideoId: oneAchieverData.achiever_intro_video_id,
        thumbnailURL: oneAchieverData.thumbnail_url,
        duration: oneAchieverData.duration_in_secs,
        title: oneAchieverData.title,
      }));
      setShow(true);
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setStartUpload(false);
    }

    try {
      const getAllLanguages = await Axios.get(
        `${BASE_URL}/master_data/languages`,
        Auth
      );
      console.log(getAllLanguages.data.data);
      tempLanguageArray = getAllLanguages.data.data;
      tempLanguageArray = tempLanguageArray.map((language) => {
        return { value: language.code, label: language.name };
      });
      setAllLanguages(tempLanguageArray);
      setStartUpload(false);
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setStartUpload(false);
    }
  }

  async function fetchTableFirst() {
    setStartUpload(true);
    setStatus("All");
    setNumberOfRows(0);
    setNoData(false);
    try {
      const rowCount = await Axios.get(
        `${BASE_URL}/achievers/search/count?country_id=${navCountryId}&search_text=${navSearch}`,
        Auth
      );
      console.log(rowCount.data.data.count);
      setNumberOfRows(rowCount.data.data.count);
      if (+rowCount.data.data.count !== 0) {
        const getTable = await Axios.get(
          `${BASE_URL}/achievers?limit=10&offset=${offSet}&country_id=${navCountryId}&search_text=${navSearch}`,
          Auth
        );
        console.log(getTable.data.data);
        setTableData(getTable.data.data);
      }
      if (+rowCount.data.data.count === 0) {
        setNoData(true);
        // setStartUpload(false);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setStartUpload(false);
    }
    try {
      const getCountry = await Axios.get(
        `${BASE_URL}/master_data/countries/`,
        Auth
      );
      console.log(getCountry.data.data);
      setCountryData(getCountry.data.data);
      setStartUpload(false);
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setStartUpload(false);
    }
  }

  async function fetchTable() {
    setStartUpload(true);
    setStatus("All");
    setNumberOfRows(0);
    setNoData(false);
    try {
      const rowCount = await Axios.get(
        `${BASE_URL}/achievers/search/count?country_id=${navCountryId}&search_text=${navSearch}`,
        Auth
      );
      console.log(rowCount.data.data.count);
      setNumberOfRows(rowCount.data.data.count);
      if (+rowCount.data.data.count !== 0) {
        const getTable = await Axios.get(
          `${BASE_URL}/achievers?limit=10&offset=${offSet}&country_id=${navCountryId}&search_text=${navSearch}`,
          Auth
        );
        console.log(getTable.data.data);
        setTableData(getTable.data.data);
        setStartUpload(false);
      }
      if (+rowCount.data.data.count === 0) {
        setNoData(true);
        setStartUpload(false);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setStartUpload(false);
    }
  }

  useEffect(() => {
    fetchTableFirst();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStatus = () => {
    return setUserDetails(!userDetails);
  };

  async function handleEnabled() {
    setStartUpload(true);
    setStatus("Enabled");
    setPageNumber(1);
    setOffSet(0);
    setNoData(false);
    try {
      console.log(737);
      const rowCount = await Axios.get(
        `${BASE_URL}/achievers/search/count?is_enabled=true&country_id=${navCountryId}&search_text=${navSearch}`,
        Auth
      );
      console.log(rowCount.data.data.count);
      setNumberOfRows(rowCount.data.data.count);
      if (+rowCount.data.data.count !== 0) {
        const getTable = await Axios.get(
          `${BASE_URL}/achievers?limit=10&offset=0&status=true&country_id=${navCountryId}&search_text=${navSearch}`,
          Auth
        );
        console.log(getTable.data.data);
        setTableData(getTable.data.data);
        setStartUpload(false);
      }
      if (+rowCount.data.data.count === 0) {
        setNoData(true);
        setStartUpload(false);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setStartUpload(false);
    }
    handleStatus();
  }

  async function handleDisabled() {
    setStartUpload(true);
    setStatus("Disabled");
    setPageNumber(1);
    setOffSet(0);
    setNoData(false);
    try {
      console.log(77);
      const rowCount = await Axios.get(
        `${BASE_URL}/achievers/search/count?is_enabled=false&country_id=${navCountryId}&search_text=${navSearch}`,
        Auth
      );
      console.log(rowCount.data.data.count);
      setNumberOfRows(rowCount.data.data.count);
      if (+rowCount.data.data.count !== 0) {
        const getTable = await Axios.get(
          `${BASE_URL}/achievers?limit=10&offset=0&status=false&country_id=${navCountryId}&search_text=${navSearch}`,
          Auth
        );
        console.log(getTable.data.data);
        setTableData(getTable.data.data);
        setStartUpload(false);
      }
      if (+rowCount.data.data.count === 0) {
        setNoData(true);
        setStartUpload(false);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setStartUpload(false);
    }
    handleStatus();
  }

  async function handleAll() {
    setStartUpload(true);
    setStatus("All");
    setPageNumber(1);
    setOffSet(0);
    setNoData(false);
    try {
      console.log(87);
      const rowCount = await Axios.get(
        `${BASE_URL}/achievers/search/count?country_id=${navCountryId}&search_text=${navSearch}`,
        Auth
      );
      console.log(rowCount.data.data.count);
      setNumberOfRows(rowCount.data.data.count);
      if (+rowCount.data.data.count !== 0) {
        const getTable = await Axios.get(
          `${BASE_URL}/achievers?limit=10&offset=0&country_id=${navCountryId}&search_text=${navSearch}`,
          Auth
        );
        console.log(getTable.data.data);
        setTableData(getTable.data.data);
        setStartUpload(false);
      }
      if (+rowCount.data.data.count === 0) {
        setNoData(true);
        setStartUpload(false);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setStartUpload(false);
    }
    handleStatus();
  }

  function handleChange(e) {
    console.log(123, e.target.value, e.target.name);
    var selectedOption = e.target.name;
    if (selectedOption === "name") {
      achieverName = e.target.value;
    }
    if (selectedOption === "tileCaption") {
      tileCaption = e.target.value;
    }
    if (selectedOption === "title") {
      title = e.target.value;
    }
    if (selectedOption === "aboutLink") {
      aboutLink = e.target.value;
    }
    if (selectedOption === "achieverCode") {
      achieverCode = e.target.value;
    }
    // if (selectedOption === "introVideoURL") {
    //   introVideoURL = e.target.value;
    // }
    // if (selectedOption === "introVideoId") {
    //   introVideoId = e.target.value;
    // }
    if (selectedOption === "country") {
      countryId = e.target.value;
    } else if (selectedOption === "thumbnailFile") {
      let thumbnailFile = e.target.files[0];
      if (thumbnailFile) {
        console.log(thumbnailFile.name, e.target.files[0]);
        setAttThumbnailFile(thumbnailFile);
      } else {
        sourceFilePathThumbnail = "";
        setPic("");
        setAttThumbnailFile("");
        setUploadThumbnail(false);
        setUploadedThumbnail(false);
      }
    }
    setNotFilled(false);
  }

  async function handleDelete(rows) {
    setPopUp(true);
    setDeleteRow(true);
    console.log(123, deleteRow, popUp);
    if (deleteRow === true) {
      console.log(456);
      setStartUpload(true);
      try {
        const deleteRow = await Axios.delete(
          `${BASE_URL}/achievers/${rows.achiever_id}`,
          Auth
        );
        console.log(deleteRow.data.data);
        toast.success("Successfully Deleted Row");
        setStartUpload(true);
        fetchTable();
      } catch (error) {
        if (error.response && error.response.data) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
        }
        setStartUpload(false);
      }
      setPopUp(false);
      setDeleteRow(false);
    }
    if (deleteRow === false) {
      rowData = rows;
      console.log(rowData, popUp);
    }
  }

  function okDelete() {
    // setDeleteNode(true);
    handleDelete(rowData);
    // setPopUp(false);
  }

  //function of cancel in popup
  function cancel() {
    setDeleteRow(false);
    setPopUp(false);
  }

  function handleNavbar(e, selectedOption) {
    if (selectedOption === "country") {
      var tempNavCountryId = e.target.value;
      setPageNumber(1);
      setOffSet(0);
      setNoData(false);
      setNavCountryId(tempNavCountryId);
    }
    // fetchTable();
  }
  function handleNavSearch(e) {
    var tempNavSearch = e.target.value;
    setPageNumber(1);
    setOffSet(0);
    setNoData(false);
    setNavSearch(tempNavSearch);
    // fetchTable();
  }

  useEffect(() => {
    fetchTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navCountryId, navSearch]);

  function uploadThumbnailFile(e) {
    if (attThumbnailFile !== undefined || attThumbnailFile !== "") {
      setUploadThumbnail(true);
      setUploadedThumbnail(false);
      // var file = copiableData;
      // formData({ ...formData, copiable_data_file_path: file });
      var newDate = new Date();
      var date =
        newDate.getDate() +
        "-" +
        (newDate.getMonth() + 1) +
        "-" +
        newDate.getFullYear() +
        "-" +
        newDate.getHours() +
        "-" +
        newDate.getMinutes() +
        "-" +
        newDate.getSeconds();
      FilePathThumbnail =
        "stucle-notes/achiever/achiever-info/" +
        date +
        "-" +
        userId +
        "-" +
        attThumbnailFile.name;
      sourceFilePathThumbnail = UPLOAD_URL + FilePathThumbnail;
      console.log(sourceFilePathThumbnail);
    }
    const params = {
      Body: attThumbnailFile,
      Bucket: `${configuration.bucketName}`,
      Key: FilePathThumbnail,
    };
    s3.putObject(params)
      .on("build", (request) => {
        request.httpRequest.headers.Host = `${configuration.digitalOceanSpaces}`;
        request.httpRequest.headers["Content-Length"] = attThumbnailFile.size;
        request.httpRequest.headers["Content-Type"] = attThumbnailFile.type;
        request.httpRequest.headers["x-amz-acl"] = "public-read";
        // console.log(request.response.httpResponse);
        setUploadThumbnail(false);
        setUploadedThumbnail(true);
      })
      .send((err) => {
        if (err) {
          console.log(err, "Img error");
          toast.error(err.response.data.message);
          setUploadThumbnail(false);
        }
      });
  }

  function paginationClick(number, offSetNumber) {
    setPageNumber(+number);
    setOffSet(+offSetNumber);
    setStartUpload(true);
  }

  return (
    <div>
      <NavbarInfo
        handleNavbar={handleNavbar}
        handleNavSearch={handleNavSearch}
      />
      <div className="container-fluid">
        {startUpload ? (
          <ReactLoading
            type="spin"
            color="#2f80ed"
            className="startUploadLoadSpin"
          />
        ) : null}
        <div className="head2">
          <div>
            <h1 className="h1AchieversInfo">Achievers info</h1>
          </div>
          <div className="headButton">
            <button className="statusButton" onClick={handleStatus}>
              {status}
            </button>

            <button className="addAchieverButton" onClick={handleShow}>
              Add Achiever
            </button>
          </div>
          <div
            onClick={handleStatus}
            className={userDetails ? "overlay " : "noOverlay "}
          ></div>
          <div className={userDetails ? "allStatusDropdown" : "noDropdown"}>
            <button
              className={
                status === "Enabled" ? "logoutButtonBlue" : "logoutButton"
              }
              onClick={handleEnabled}
            >
              Enabled
            </button>
            <button
              className={
                status === "Disabled" ? "logoutButtonBlue" : "logoutButton"
              }
              onClick={handleDisabled}
            >
              Disabled
            </button>
            <button
              className={status === "All" ? "logoutButtonBlue" : "logoutButton"}
              onClick={handleAll}
            >
              All
            </button>
          </div>
        </div>
        {noData ? (
          <h5 className="loadingTextData">Data is not available.</h5>
        ) : (
          <div>
            <div>
              <table className="tableAch">
                <thead>
                  <tr>
                    {thead_column.map((el, key) => {
                      return <th key={key}>{el}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((rows, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          <div>
                            {/* <button
                        className="editButton"
                        onClick={() =>
                          handleEdit(
                            rows.achiever_full_name,
                            rows.tile_caption,
                            rows.country_id,
                            rows.achiever_id,
                            rows.achiever_code,
                            rows.about_html_url
                          )
                        }
                      >
                        <img src={EditIcon} alt="" />
                      </button> */}
                            <button
                              className="talkDeleteIconBtn"
                              onClick={() => handleEdit(rows)}
                            >
                              <EditIcon className="talkDeleteIcon" />
                            </button>
                            <button
                              className="talkDeleteIconBtn"
                              onClick={(e) => handleDelete(rows)}
                            >
                              <DeleteIcon className="talkDeleteIcon" />
                            </button>
                          </div>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            className="checkbox"
                            id=""
                            name="checkbox"
                            // value={rows.is_enabled}
                            checked={rows.is_enabled}
                            onChange={(e) => handleCheckbox(e, rows)}
                          />
                        </td>
                        <td>{rows.country_name}</td>
                        <td>{rows.achiever_code}</td>
                        <td className="rowName">{rows.achiever_full_name}</td>
                        <td className="rowText">{rows.title}</td>
                        <td className="tileCaption">{rows.tile_caption}</td>
                        <td>{rows.course_name}</td>
                        <td className="rowText">{rows.notes_count}</td>
                        <td className="rowText">{rows.talks_count}</td>
                        <td className="rowText">{rows.duration_in_secs}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            {/* <div className="paginationInfo">
            <Stack spacing={2} align="center">
              <Pagination
                count={5}
                color="primary"
                align="center"
                showFirstButton
                showLastButton
              />
            </Stack>
          </div> */}

            <Pagination
              paginationClick={paginationClick}
              numberOfRows={numberOfRows}
              offSet={offSet}
              pageNumber={pageNumber}
            />
          </div>
        )}

        <ModalComponent
          show={show}
          onClick={handleClose}
          // hideBackdrop={true}
          // disableBackdropClick={true}
          onChange={handleChange}
          title={editValue ? "Edit Achievers" : "Add Achievers"}
          className="addAchieverPopup"
        >
          <div className="addAchieverForm">
            <Formik
              initialValues={editValue ? rowValues : initialValues}
              // validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ handleChange, setFieldValue }) => {
                return (
                  <div>
                    <Form>
                      <label
                        className={
                          notFilled && achieverName === ""
                            ? "lableAchieverRed"
                            : "lableAchiever"
                        }
                      >
                        Name
                      </label>
                      <br />
                      <Field
                        type="text"
                        name="name"
                        className="input"
                        // value={editValue ? rowValues.name : undefined}
                      ></Field>
                      <br />
                      <label
                        className={
                          notFilled && tileCaption === ""
                            ? "lableAchieverRed"
                            : "lableAchiever"
                        }
                      >
                        Tile caption
                      </label>
                      <br />
                      <Field
                        type="text"
                        name="tileCaption"
                        className="input"
                        // value={editValue === true ? rowValues.title : undefined}
                      ></Field>
                      <br />
                      {/* <ErrorMessage name="name" component="div">
                             {(error) => <div className={styles.errors}>{error}</div>}
                            </ErrorMessage> */}
                      <label
                        className={
                          notFilled && title === ""
                            ? "lableAchieverRed"
                            : "lableAchiever"
                        }
                      >
                        Title
                      </label>
                      <br />
                      <Field
                        type="text"
                        name="title"
                        className="input"
                        // value={editValue === true ? rowValues.title : undefined}
                      ></Field>
                      <br />

                      <label
                        className={
                          notFilled && aboutLink === ""
                            ? "lableAchieverRed"
                            : "lableAchiever"
                        }
                      >
                        About link
                      </label>
                      <br />
                      <Field type="text" name="aboutLink" className="input" />

                      <br />
                      <label
                        className={
                          notFilled && achieverCode === ""
                            ? "lableAchieverRed"
                            : "lableAchiever"
                        }
                      >
                        Achiever Code
                      </label>
                      <br />
                      <Field
                        type="text"
                        name="achieverCode"
                        className="input"
                      />

                      <br />
                      <label className="lableAchiever">Intro Video URL</label>
                      <br />
                      <Field
                        type="text"
                        name="introVideoURL"
                        className="input"
                      />

                      <br />
                      <label className="lableAchiever">Intro Video Id</label>
                      <br />
                      <Field
                        type="text"
                        name="introVideoId"
                        className="input"
                      />

                      <br />
                      <label className="lableAchiever">Duration</label>
                      <br />
                      <Field type="number" name="duration" className="input" min="0" />

                      <br />
                      <label
                        className="lableAchiever"
                      >
                        Subtitle
                      </label>
                      <br />
                      <MultiSelect
                        options={allLanguages}
                        value={selected}
                        onChange={setSelected}
                        labelledBy="Select"
                        hasSelectAll={false}
                        className="selectOption1"
                      />

                      <label
                        className={
                          notFilled && countryId === -1
                            ? "lableAchieverRed"
                            : "lableAchiever"
                        }
                      >
                        Country
                      </label>
                      <br />
                      <Field
                        as="select"
                        name="country"
                        onChange={handleChange}
                        className="selectOption"
                        // value={editValue === true ? rowValues.country : undefined}
                      >
                        <option value="-1">Country</option>
                        {countryData.map((e, key) => {
                          return (
                            <option key={key} value={e.country_id}>
                              {e.country_name}
                            </option>
                          );
                        })}
                      </Field>

                      <br />

                      <label
                        className={
                          notFilled && sourceFilePathThumbnail === ""
                            ? "lableAchieverRed"
                            : "lableAchiever"
                        }
                      >
                        Thumbnail Image
                      </label>
                      <br />
                      <label htmlFor="attachFIle" className="talkThumbnail">
                        Attach thumbnail
                      </label>
                      <div
                        className={
                          // attachedFile === ""
                          // ? "talkThumbnailHiddenTextBefore"
                          // :
                          attThumbnailFile !== ""
                            ? "talkThumbnailHiddenTextAfter"
                            : "talkThumbnailHiddenText"
                        }
                      >
                        <img
                          src={
                            editValue
                              ? rowValues.thumbnailURL
                              : pic
                              ? URL.createObjectURL(pic)
                              : null
                          }
                          alt={pic ? pic.name : null}
                          className={
                            editValue
                              ? "talkPreviewImg"
                              : pic
                              ? "talkPreviewImg"
                              : ""
                          }
                        />
                        <input
                          id="thumbnailFile"
                          name="thumbnailFile"
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onClick={(event) => (event.target.value = null)}
                          onChange={(event) => {
                            setFieldValue("file", event.currentTarget.files[0]);
                            setPic(event.currentTarget.files[0]);
                            if (event.currentTarget.files[0]) {
                              var newURL = URL.createObjectURL(
                                event.currentTarget.files[0]
                              );
                              setRowValues({
                                ...rowValues,
                                thumbnailURL: newURL,
                              });
                            }
                          }}
                          className="talkThumbnailbutton"
                          // disabled={attThumbnailFile !== "" ? false : true}
                        />
                        {pic ? (
                          <button
                            name="removeFile"
                            type="button"
                            onClick={() => {
                              sourceFilePathThumbnail = "";
                              setPic("");
                              setAttThumbnailFile("");
                              setUploadThumbnail(false);
                              setUploadedThumbnail(false);
                              setRowValues({
                                ...rowValues,
                                thumbnailURL: null,
                              });
                            }}
                            className="talkThumbnailremoveButton"
                          >
                            Remove{" "}
                          </button>
                        ) : null}
                      </div>
                      {attThumbnailFile !== "" ? (
                        <div>
                          <p className="fileName">{attThumbnailFile.name}</p>
                          <div className="notesUploadDiv">
                            <button
                              type="button"
                              onClick={uploadThumbnailFile}
                              className="btnUpload"
                              disabled={uploadedThumbnail}
                            >
                              Upload
                            </button>
                            <div>
                              {uploadThumbnail ? (
                                <div className="noteLoadingDiv">
                                  <ReactLoading
                                    type="spinningBubbles"
                                    color="#2f80ed"
                                    className="uploadLoadSpin"
                                  />
                                  <p>Uploading...</p>
                                </div>
                              ) : null}
                              {uploadedThumbnail ? <p>File Uploaded</p> : null}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <hr />

                      <Box component="div" className="footerAddAchiever">
                        <button
                          type="button"
                          onClick={handleClose}
                          className="btnCancel"
                        >
                          <div>Cancel</div>
                        </button>

                        <button type="submit" className="btnSave">
                          <div>Save</div>
                        </button>
                      </Box>
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </div>
        </ModalComponent>
        <div onClick={cancel} className={popUp ? "popUpBack" : "noPopUp"}>
          <div className={popUp ? "yesPopUp" : "noPopUp"}>
            <button onClick={cancel} className="popUpCloseBtn">
              <CloseOutlinedIcon className="closeIcon" />
            </button>
            <div className="warningDiv">
              <ReportOutlinedIcon className="warningIcon" />
            </div>
            <h2 className="popUph2">Are You Sure?</h2>
            <div className="popUpButtonDiv">
              <button onClick={cancel} className="popUpCancelButton">
                Cancel
              </button>
              <button onClick={okDelete} className="popUpOkButton">
                OK
              </button>
            </div>
          </div>
        </div>
        <ToastContainer
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
}

export default AchieversInfo;
