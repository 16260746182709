import React, { useState, useEffect } from "react";
// import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
import Pagination from "../../SubComponents/Pagination/Pagination";
// import rows from "../../../achieversTalksData.js";
// import EditIcon from "../../../assets/images/EditIcon.svg";
import ModalComponent from "../../Modal/Modal";
import "./achieversTalks.css";
import { Formik, Form, Field } from "formik";
// import * as Yup from "yup";
import Box from "@mui/material/Box";
// import { Redirect } from "react-router-dom";
// import FormControl from "@mui/material/FormControl";
// import ListItemText from "@mui/material/ListItemText";
// import Checkbox from "@mui/material/Checkbox";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
// import Select, { components } from "react-select";
import { BASE_URL } from "../../config/Api";
import Axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import ReactLoading from "react-loading";
import configuration from "../../config/Config";
import s3 from "../../config/DigitalOcean";
import { UPLOAD_URL } from "../../config/Api.js";
import { MultiSelect } from "react-multi-select-component";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import NavbarTalks from "./Header/NavbarTalks";
const initialValues = {
  country: -1,
  board: -1,
  course: -1,
  group: "",
  audioLanguage: -1,
  theme: "",
  title: "",
  subtitle: [],
  videoLink: "",
  videoId: "",
  achieversId: "",
  name: "",
  achieverCode: "",
  attachFile: null,
  thumbnailFile: null,
  duration: "",
  rating: "",
};
var country_id = -1;
var board_id = -1;
var medium_id = -1;
var course_id = -1;
var achieverCode = "";
var title = "";
var videoLink = "";
var videoId = "";
var duration = "";
var rating = "";
var FilePathThumbnail;
// var FilePreviewPath;
var sourceFilePathThumbnail = "";
// var sourcePreviewImage = "";
var tempTableData = [];
var subtitleJson = [];
var tempLanguageArray = [];
var rowData;
var navCountry_id = -1;
var navBoard_id = -1;
var navMedium_id = -1;
var navCourse_id = -1;
// var numberOfPages = 1;
var oneAchieverData;
// const names = [
//   { value: "Kannada", label: "Kannada" },
//   { value: "English", label: "English" },
//   { value: "Tamil", label: "Tamil" },
//   { value: "Hindi", label: "Hindi" },
//   { value: "Telugu", label: "Telugu" },
//   { value: "Bodo", label: "Bodo" },
// ];

// const names = ["Kannada", "English", "Telugu", "Hindi"];

// const validationSchema = Yup.object({
//   email: Yup.string().required("Email is required"),
//   password: Yup.string().required("Password is required"),
// });

// const Option = (props) => {
//   return (
//     <div>
//       <components.Option {...props}>
//         <input
//           type="checkbox"
//           checked={props.isSelected}
//           onChange={() => null}
//           className="talksCheckbox"
//         />{" "}
//         <label>{props.label}</label>
//       </components.Option>
//     </div>
//   );
// };

const thead_column = [
  "Edit",
  "Enable",
  "Country",
  "Achiever ID",
  "Name",
  "Updated Date",
  "Title",
  "Duration",
  "Board",
  "Courses",
  "Audio Language",
  "Subtitle",
  "Theme",
  "Rating",
  "Views",
];

// const countries = ["Country", "India", "Nepal", "Shrilanka"];
// const boards = ["All Board", "State", "CBSE", "ICSE"];
// const courses = [
//   "All Courses",
//   "2nd",
//   "3rd",
//   "4th",
//   "5th",
//   "6th",
//   "7th",
//   "8th",
//   "9th",
//   "10th",
// ];
// const groups = ["All Groups", "Kannada", "Science", "Mathematics"];
// const languages = ["Kannada", "English", "Telugu"];
const themes = ["None", "100", "200", "300"];

function AchieversTalks() {
  const [show, setShow] = useState(false);
  const [pic, setPic] = useState("");
  const [editValue, setEditValue] = useState(false);
  // const [selectOption, setSelectOption] = useState([]);
  // const [personName, setPersonName] = useState([]);
  // const [personN, setPersonN] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [startUpload, setStartUpload] = useState(false);
  const [attThumbnailFile, setAttThumbnailFile] = useState("");
  const [uploadThumbnail, setUploadThumbnail] = useState(false);
  const [uploadedThumbnail, setUploadedThumbnail] = useState(false);
  // const [attachedFile, setAttachedFile] = useState("");
  // const [upload, setUpload] = useState(false);
  // const [uploaded, setUploaded] = useState(false);
  const [status, setStatus] = useState("All");
  const [userDetails, setUserDetails] = useState(false);
  const [notFilled, setNotFilled] = useState(false);
  const [selected, setSelected] = useState([]);
  const [popUp, setPopUp] = useState(false);
  const [deleteRow, setDeleteRow] = useState(false);
  const [rowValues, setRowValues] = useState({
    country: -1,
    achieversId: 0,
    achieversCode: -1,
    name: "",
    title: "",
    duration: 0,
    board: -1,
    course: -1,
    subtitle: "",
    language: -1,
    theme: 0,
    rating: 0,
    views: 0,
    videoLink: "",
    achieverCode: "",
  });
  const [dropDownData, setDropDownData] = useState({
    country: [],
    allBoards: [],
    allMediums: [],
    allCourses: [],
    allLanguages: [],
  });
  const [navSearch, setNavSearch] = useState("");
  const [state, setstate] = useState({
    country: -1,
    board: -1,
    course: -1,
    medium: -1,
  });
  const [navbarData, setNavbarData] = useState({
    country: [],
    allBoards: [],
    allMediums: [],
    allCourses: [],
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [offSet, setOffSet] = useState(0);
  const [numberOfRows, setNumberOfRows] = useState(0);
  const [noData, setNoData] = useState(false);

  const token = window.localStorage.getItem("token");
  const userId = window.localStorage.getItem("userId");
  const Auth = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  useEffect(() => {
    async function handlePagination() {
      console.log(offSet);
      try {
        // const rowCount = await Axios.get(
        //   `${BASE_URL}/achiever_talks/search/count?country_id=${navCountry_id}&medium_id=${navMedium_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&search_text=${navSearch}`,
        //   Auth
        // );
        // console.log(rowCount.data.data.count);
        // setNumberOfRows(rowCount.data.data.count);
        // if (+rowCount.data.data.count !== 0) {
        const getTable = await Axios.get(
          `${BASE_URL}/achievers/tabular/talks?limit=10&offset=${offSet}&country_id=${navCountry_id}&medium_id=${navMedium_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&search_text=${navSearch}`,
          Auth
        );
        console.log(getTable.data.data);
        setTableData(getTable.data.data);
        setStartUpload(false);
        // }
        // if (+rowCount.data.data.count === 0) {
        //   setNoData(true);
        //   setStartUpload(false);
        // }
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response);
          toast.error(err.response.data.message);
        }
        setStartUpload(false);
      }
    }
    async function handlePaginationEnabled() {
      console.log(offSet);
      try {
        const getTable = await Axios.get(
          `${BASE_URL}/achievers/tabular/talks?limit=10&offset=${offSet}&is_enabled=true&country_id=${navCountry_id}&medium_id=${navMedium_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&search_text=${navSearch}`,
          Auth
        );
        console.log(getTable.data.data);
        setTableData(getTable.data.data);
        setStartUpload(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response);
          toast.error(err.response.data.message);
        }
        setStartUpload(false);
      }
    }

    async function handlePaginationDisabled() {
      console.log(offSet);
      try {
        const getTable = await Axios.get(
          `${BASE_URL}/achievers/tabular/talks?limit=10&offset=${offSet}&is_enabled=false&country_id=${navCountry_id}&medium_id=${navMedium_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&search_text=${navSearch}`,
          Auth
        );
        console.log(getTable.data.data);
        setTableData(getTable.data.data);
        setStartUpload(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response);
          toast.error(err.response.data.message);
        }
        setStartUpload(false);
      }
    }

    if (status === "All") {
      handlePagination();
    }
    if (status === "Enabled") {
      handlePaginationEnabled();
    }
    if (status === "Disabled") {
      handlePaginationDisabled();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offSet]);

  const handleClose = () => {
    country_id = -1;
    board_id = -1;
    medium_id = -1;
    course_id = -1;
    // sourcePreviewImage = "";
    sourceFilePathThumbnail = "";
    achieverCode = "";
    title = "";
    videoLink = "";
    videoId = "";
    duration = "";
    rating = "";
    setShow(false);
    setEditValue(false);
    // setPersonName([]);
    setPic("");
    setAttThumbnailFile("");
    setUploadThumbnail(false);
    setUploadedThumbnail(false);
    setRowValues({});
    setSelected([]);
    // setSelectOption([]);
    // setAttachedFile("");
    // setUpload(false);
    // setUploaded(false);
    setStartUpload(false);
    setNotFilled(false);
  };
  const handleShow = () => setShow(true);

  async function onSubmit(value) {
    console.log(value);
    setStartUpload(true);
    console.log(rowValues);
    if (
      value.country === -1 ||
      value.title === "" ||
      value.duration === "" ||
      value.board === -1 ||
      value.course === -1 ||
      value.language === -1 ||
      selected === [] ||
      value.videoLink === "" ||
      sourceFilePathThumbnail === "" ||
      value.rating === "" ||
      value.videoId === ""
      // sourcePreviewImage === ""
    ) {
      toast.error("Please enter details in all the fields");
      setStartUpload(false);
      setNotFilled(true);
    } else {
      if (editValue) {
        subtitleJson = selected.map((language) => {
          return { name: language.label, code: language.value };
        });
        subtitleJson = JSON.stringify(subtitleJson);
        const editData = {
          country_id: value.country,
          title: value.title,
          duration_in_secs: value.duration,
          board_id: value.board,
          course_id: value.course,
          audio_language_id: value.language,
          subtitle_languages_json: subtitleJson,
          video_url: value.videoLink,
          video_id: value.videoId,
          thumbnail_url: sourceFilePathThumbnail,
          rating: value.rating,
          // preview_image_url: sourcePreviewImage,
          is_enabled: rowValues.isEnabled,
        };
        console.log(editData);
        try {
          const editTalk = await Axios.put(
            `${BASE_URL}/achievers/${rowValues.achieversId}/talks/${rowValues.achieverTalkId}`,
            editData,
            Auth
          );
          console.log(editTalk);
          toast.success("Successfully Edited Achiever talk");
          setStartUpload(false);
          handleClose();
          fetchTable();
        } catch (error) {
          if (error.response && error.response.data) {
            console.log(error.response.data.message);
            toast.error(error.response.data.message);
          }
          handleClose();
          setStartUpload(false);
        }
      } else {
        subtitleJson = selected.map((language) => {
          return { name: language.label, code: language.value };
        });
        subtitleJson = JSON.stringify(subtitleJson);
        console.log(subtitleJson);
        const data = {
          country_id: value.country,
          title: value.title,
          duration_in_secs: value.duration,
          board_id: value.board,
          course_id: value.course,
          audio_language_id: value.language,
          subtitle_languages_json: subtitleJson,
          video_url: value.videoLink,
          thumbnail_url: sourceFilePathThumbnail,
          rating: value.rating,
          video_id: value.videoId,
          // preview_image_url: sourcePreviewImage,
          is_enabled: true,
        };
        console.log(data);
        try {
          const newTalk = await Axios.post(
            `${BASE_URL}/achievers/${value.achieverCode}/talks`,
            data,
            Auth
          );
          console.log(newTalk);
          toast.success("Successfully Added Achiever talk");
          setStartUpload(false);
          handleClose();
          fetchTable();
        } catch (error) {
          if (error.response && error.response.data) {
            console.log(error.response.data.message);
            toast.error(error.response.data.message);
          }
          handleClose();
          setStartUpload(false);
        }
      }
    }
  }

  async function handleEdit(rows) {
    setEditValue(true);
    setStartUpload(true);
    try {
      const oneAchiever = await Axios.get(
        `${BASE_URL}/achievers/${rows.achiever_id}/talks/${rows.achiever_talk_id}`,
        Auth
      );
      console.log(oneAchiever.data.data);
      oneAchieverData = oneAchiever.data.data;
      var reqLanguageType = Array.isArray(
        oneAchieverData.subtitle_languages_json
      )
        ? oneAchieverData.subtitle_languages_json.map((language) => {
            return {
              value: language.code,
              label: language.name,
            };
          })
        : [];
      console.log(reqLanguageType);
      setSelected(reqLanguageType);
      // setSelectOption(
      //   Array.isArray(rows.subtitle_languages_json)
      //     ? rows.subtitle_languages_json.map((language) => {
      //         return language.name;
      //       })
      //     : []
      // );
      country_id = oneAchieverData.country_id;
      board_id = oneAchieverData.board_id;
      medium_id = oneAchieverData.medium_id;
      course_id = oneAchieverData.course_id;
      sourceFilePathThumbnail = oneAchieverData.thumbnail_url;
      // sourcePreviewImage = rows.preview_image_url;
      console.log(sourceFilePathThumbnail);
      achieverCode = oneAchieverData.achiever_code;
      title = oneAchieverData.title;
      videoLink = oneAchieverData.video_url;
      videoId = oneAchieverData.video_id;
      duration = oneAchieverData.duration_in_secs;
      rating = oneAchieverData.rating;
      setRowValues((prevState) => ({
        ...prevState,
        country: oneAchieverData.country_id,
        achieversId: oneAchieverData.achiever_id,
        achieversCode: oneAchieverData.achiever_code,
        name: oneAchieverData.achiever_name,
        title: oneAchieverData.title,
        duration: oneAchieverData.duration_in_secs,
        board: oneAchieverData.board_id,
        course: oneAchieverData.course_id,
        subtitle: reqLanguageType,
        language: oneAchieverData.audio_language_id,
        theme: oneAchieverData.theme,
        rating: oneAchieverData.rating,
        views: oneAchieverData.views,
        videoLink: oneAchieverData.video_url,
        videoId: oneAchieverData.video_id,
        achieverCode: oneAchieverData.achiever_code,
        achieverTalkId: oneAchieverData.achiever_talk_id,
        isEnabled: oneAchieverData.is_enabled,
        thumbnailURL: oneAchieverData.thumbnail_url,
      }));
      setShow(true);
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setStartUpload(false);
    }

    try {
      // if (name === "country") {
      const getBoard = await Axios.get(
        `${BASE_URL}/master_data/boards?country_id=${country_id}`,
        Auth
      );
      console.log(getBoard.data.data);
      setDropDownData({ ...dropDownData, allBoards: getBoard.data.data });
      // } else if (name === "board") {
      const getMedium = await Axios.get(
        `${BASE_URL}//master_data/mediums?country_id=${country_id}`,
        Auth
      );
      console.log(getMedium.data.data);
      setDropDownData((prevState) => ({
        ...prevState,
        allMediums: getMedium.data.data,
      }));
      // } else if (name === "language") {
      const getCourse = await Axios.get(
        `${BASE_URL}/master_data/courses?board_id=${board_id}`,
        Auth
      );
      console.log(getCourse.data.data);
      setDropDownData((prevState) => ({
        ...prevState,
        allCourses: getCourse.data.data,
      }));
      const getAllLanguages = await Axios.get(
        `${BASE_URL}/master_data/languages`,
        Auth
      );
      console.log(getAllLanguages.data.data);
      tempLanguageArray = getAllLanguages.data.data;
      tempLanguageArray = tempLanguageArray.map((language) => {
        return { value: language.code, label: language.name };
      });
      setDropDownData((prevState) => ({
        ...prevState,
        allLanguages: tempLanguageArray,
      }));
      // } else if (name === "course") {

      // } else if (name === "subject") {
      setStartUpload(false);
      // }
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
      setStartUpload(false);
    }
  }

  // var subtitle1 = rowValues.subtitle;
  // console.log(rowValues.subtitle);

  // function handleSelect(e) {
  //   setSelectOption(Array.isArray(e) ? e.map((x) => x.label) : []);
  // }
  // console.log(selectOption);
  console.log(selected);

  // function handleDropDown(event) {
  //   const newValue = event.target.value;
  //   setPersonN(newValue);
  //   console.log(personN);
  // }

  // const handleChangeDropDown = (event) => {
  //   // const {
  //   //   target: { value },
  //   // } = event;
  //   const value = event.target.value;
  //   setPersonName(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };
  // console.log(personName);

  // function handleEditProp() {
  //   setPersonName(rowValues.subtitle);
  // }

  async function fetchTableFirst() {
    setStartUpload(true);
    setStatus("All");
    setNumberOfRows(0);
    setNoData(false);
    try {
      const rowCount = await Axios.get(
        `${BASE_URL}/achiever_talks/search/count?country_id=${navCountry_id}&medium_id=${navMedium_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&search_text=${navSearch}`,
        Auth
      );
      console.log(rowCount.data.data.count);
      setNumberOfRows(rowCount.data.data.count);
      if (+rowCount.data.data.count !== 0) {
        const getTable = await Axios.get(
          `${BASE_URL}/achievers/tabular/talks?limit=10&offset=${offSet}&country_id=${navCountry_id}&medium_id=${navMedium_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&search_text=${navSearch}`,
          Auth
        );
        console.log(getTable.data.data);
        setTableData(getTable.data.data);
        // setStartUpload(false);
      }
      if (+rowCount.data.data.count === 0) {
        setNoData(true);
        // setStartUpload(false);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.meeaa);
      }
      setStartUpload(false);
    }
    try {
      const getCountry = await Axios.get(
        `${BASE_URL}/master_data/countries/`,
        Auth
      );
      console.log(getCountry.data);
      setDropDownData({ ...dropDownData, country: getCountry.data.data });
      setNavbarData({ ...navbarData, country: getCountry.data.data });
      setStartUpload(false);
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setStartUpload(false);
    }
  }

  async function fetchTable() {
    setStartUpload(true);
    setStatus("All");
    setNumberOfRows(0);
    setNoData(false);
    try {
      const rowCount = await Axios.get(
        `${BASE_URL}/achiever_talks/search/count?country_id=${navCountry_id}&medium_id=${navMedium_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&search_text=${navSearch}`,
        Auth
      );
      console.log(rowCount.data.data.count);
      setNumberOfRows(rowCount.data.data.count);
      if (+rowCount.data.data.count !== 0) {
        const getTable = await Axios.get(
          `${BASE_URL}/achievers/tabular/talks?limit=10&offset=${offSet}&country_id=${navCountry_id}&medium_id=${navMedium_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&search_text=${navSearch}`,
          Auth
        );
        console.log(getTable.data.data);
        setTableData(getTable.data.data);
        setStartUpload(false);
      }
      if (+rowCount.data.data.count === 0) {
        setNoData(true);
        setStartUpload(false);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.meeaa);
      }
      setStartUpload(false);
    }
  }

  useEffect(() => {
    fetchTableFirst();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchOtherData = async (name) => {
    setStartUpload(true);
    try {
      if (name === "country") {
        const getBoard = await Axios.get(
          `${BASE_URL}/master_data/boards?country_id=${country_id}`,
          Auth
        );
        console.log(getBoard.data.data);
        setDropDownData({ ...dropDownData, allBoards: getBoard.data.data });
        setStartUpload(false);
      } else if (name === "board") {
        const getMedium = await Axios.get(
          `${BASE_URL}/master_data/mediums?country_id=${country_id}`,
          Auth
        );
        console.log(getMedium.data.data);
        setDropDownData((prevState) => ({
          ...prevState,
          allMediums: getMedium.data.data,
        }));
        setStartUpload(false);
      } else if (name === "language") {
        const getCourse = await Axios.get(
          `${BASE_URL}/master_data/courses?board_id=${board_id}`,
          Auth
        );
        console.log(getCourse.data.data);
        setDropDownData((prevState) => ({
          ...prevState,
          allCourses: getCourse.data.data,
        }));
        setStartUpload(false);
      } else if (name === "course") {
        const getAllLanguages = await Axios.get(
          `${BASE_URL}/master_data/languages`,
          Auth
        );
        console.log(getAllLanguages.data.data);
        tempLanguageArray = getAllLanguages.data.data;
        tempLanguageArray = tempLanguageArray.map((language) => {
          return { value: language.code, label: language.name };
        });
        setDropDownData((prevState) => ({
          ...prevState,
          allLanguages: tempLanguageArray,
        }));
        setStartUpload(false);
      }
      // else if (name === "subject") {
      //   const getTopic = await Axios.get(
      //     `${BASE_URL}/master_data/topics?subject_id=${subject_id}`,
      //     Auth
      //   );
      //   console.log(getTopic.data.data);
      //   setDropDownData((prevState) => ({
      //     ...prevState,
      //     allTopics: getTopic.data.data,
      //   }));
      //   setStartUpload(false);
      // }
      else {
        setStartUpload(false);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
      setStartUpload(false);
    }
  };

  function handleChange(e) {
    console.log(123, e.target.value, e.target.name);
    var selectedOption = e.target.name;
    if (selectedOption === "country") {
      country_id = e.target.value;
    } else if (selectedOption === "board") {
      board_id = e.target.value;
    } else if (selectedOption === "language") {
      medium_id = e.target.value;
    } else if (selectedOption === "course") {
      course_id = e.target.value;
    } else if (selectedOption === "achieverCode") {
      achieverCode = e.target.value;
    } else if (selectedOption === "title") {
      title = e.target.value;
    } else if (selectedOption === "videoLink") {
      videoLink = e.target.value;
    } else if (selectedOption === "duration") {
      duration = e.target.value;
    } else if (selectedOption === "rating") {
      rating = e.target.value;
    }
    // else if (selectedOption === "previewImage") {
    //   let file = e.target.files[0];
    //   if (file) {
    //     console.log(file.name, e.target.files[0]);
    //     setAttachedFile(file);
    //   } else {
    //     FilePreviewPath = "";
    //     setAttachedFile("");
    //     setUpload(false);
    //     setUploaded(false);
    //   }
    // }
    else if (selectedOption === "thumbnailFile") {
      let thumbnailFile = e.target.files[0];
      if (thumbnailFile) {
        console.log(thumbnailFile.name, e.target.files[0]);
        setAttThumbnailFile(thumbnailFile);
      } else {
        sourceFilePathThumbnail = "";
        setPic("");
        setAttThumbnailFile("");
        setUploadThumbnail(false);
        setUploadedThumbnail(false);
      }
    }
    setNotFilled(false);
    fetchOtherData(selectedOption);
  }

  // function uploadPreviewFile(e) {
  //   if (attachedFile !== undefined || attachedFile !== "") {
  //     setUpload(true);
  //     setUploaded(false);
  //     // var file = copiableData;
  //     // formData({ ...formData, copiable_data_file_path: file });
  //     var newDate = new Date();
  //     var date =
  //       newDate.getDate() +
  //       "-" +
  //       (newDate.getMonth() + 1) +
  //       "-" +
  //       newDate.getFullYear() +
  //       "-" +
  //       newDate.getHours() +
  //       "-" +
  //       newDate.getMinutes() +
  //       "-" +
  //       newDate.getSeconds();
  //     FilePreviewPath =
  //       "stucle-notes/achiever/achiever-notes/" +
  //       date +
  //       "-" +
  //       userId +
  //       "-" +
  //       attachedFile.name;
  //     sourcePreviewImage = UPLOAD_URL + FilePreviewPath;
  //     console.log(sourcePreviewImage);
  //   }
  //   const params = {
  //     Body: attachedFile,
  //     Bucket: `${configuration.bucketName}`,
  //     Key: FilePreviewPath,
  //   };
  //   s3.putObject(params)
  //     .on("build", (request) => {
  //       request.httpRequest.headers.Host = `${configuration.digitalOceanSpaces}`;
  //       request.httpRequest.headers["Content-Length"] = attachedFile.size;
  //       request.httpRequest.headers["Content-Type"] = attachedFile.type;
  //       request.httpRequest.headers["x-amz-acl"] = "public-read";
  //       // console.log(request.response.httpResponse);
  //       setUpload(false);
  //       setUploaded(true);
  //     })
  //     .send((err) => {
  //       if (err) {
  //         console.log(err, "Img error");
  //         toast.error(err.response.data.message);
  //         setUpload(false);
  //       }
  //     });
  // }

  function uploadThumbnailFile(e) {
    if (attThumbnailFile !== undefined || attThumbnailFile !== "") {
      setUploadThumbnail(true);
      setUploadedThumbnail(false);
      // var file = copiableData;
      // formData({ ...formData, copiable_data_file_path: file });
      var newDate = new Date();
      var date =
        newDate.getDate() +
        "-" +
        (newDate.getMonth() + 1) +
        "-" +
        newDate.getFullYear() +
        "-" +
        newDate.getHours() +
        "-" +
        newDate.getMinutes() +
        "-" +
        newDate.getSeconds();
      FilePathThumbnail =
        "stucle-notes/achiever/achiever-talks/" +
        date +
        "-" +
        userId +
        "-" +
        attThumbnailFile.name;
      sourceFilePathThumbnail = UPLOAD_URL + FilePathThumbnail;
      console.log(sourceFilePathThumbnail);
    }
    const params = {
      Body: attThumbnailFile,
      Bucket: `${configuration.bucketName}`,
      Key: FilePathThumbnail,
    };
    s3.putObject(params)
      .on("build", (request) => {
        request.httpRequest.headers.Host = `${configuration.digitalOceanSpaces}`;
        request.httpRequest.headers["Content-Length"] = attThumbnailFile.size;
        request.httpRequest.headers["Content-Type"] = attThumbnailFile.type;
        request.httpRequest.headers["x-amz-acl"] = "public-read";
        // console.log(request.response.httpResponse);
        setUploadThumbnail(false);
        setUploadedThumbnail(true);
      })
      .send((err) => {
        if (err) {
          console.log(err, "Img error");
          toast.error(err.response.data.message);
          setUploadThumbnail(false);
        }
      });
  }

  const handleStatus = () => {
    return setUserDetails(!userDetails);
  };

  async function handleEnabled() {
    setStatus("Enabled");
    setStartUpload(true);
    setPageNumber(1);
    setOffSet(0);
    setNoData(false);
    try {
      console.log(737);
      const rowCount = await Axios.get(
        `${BASE_URL}/achiever_talks/search/count?is_enabled=true&country_id=${navCountry_id}&medium_id=${navMedium_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&search_text=${navSearch}`,
        Auth
      );
      console.log(rowCount.data.data.count);
      setNumberOfRows(rowCount.data.data.count);
      if (+rowCount.data.data.count !== 0) {
        const getTable = await Axios.get(
          `${BASE_URL}/achievers/tabular/talks?is_enabled=true&limit=10&offset=0&country_id=${navCountry_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&medium_id=${navMedium_id}&search_text=${navSearch}`,
          Auth
        );
        console.log(getTable.data.data);
        setTableData(getTable.data.data);
        setStartUpload(false);
      }
      if (+rowCount.data.data.count === 0) {
        setNoData(true);
        setStartUpload(false);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setStartUpload(false);
    }
    handleStatus();
  }

  async function handleDisabled() {
    setStatus("Disabled");
    setStartUpload(true);
    setPageNumber(1);
    setOffSet(0);
    setNoData(false);
    try {
      console.log(737);
      const rowCount = await Axios.get(
        `${BASE_URL}/achiever_talks/search/count?is_enabled=false&country_id=${navCountry_id}&medium_id=${navMedium_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&search_text=${navSearch}`,
        Auth
      );
      console.log(rowCount.data.data.count);
      setNumberOfRows(rowCount.data.data.count);
      if (+rowCount.data.data.count !== 0) {
        const getTable = await Axios.get(
          `${BASE_URL}/achievers/tabular/talks?is_enabled=false&limit=10&offset=0&country_id=${navCountry_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&medium_id=${navMedium_id}&search_text=${navSearch}`,
          Auth
        );
        console.log(getTable.data.data);
        setTableData(getTable.data.data);
        setStartUpload(false);
      }
      if (+rowCount.data.data.count === 0) {
        setNoData(true);
        setStartUpload(false);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setStartUpload(false);
    }
    handleStatus();
  }

  async function handleAll() {
    setStatus("All");
    setStartUpload(true);
    setPageNumber(1);
    setOffSet(0);
    setNoData(false);
    try {
      const rowCount = await Axios.get(
        `${BASE_URL}/achiever_talks/search/count?&country_id=${navCountry_id}&medium_id=${navMedium_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&search_text=${navSearch}`,
        Auth
      );
      console.log(rowCount.data.data.count);
      setNumberOfRows(rowCount.data.data.count);
      if (+rowCount.data.data.count !== 0) {
        const getTable = await Axios.get(
          `${BASE_URL}/achievers/tabular/talks?limit=10&offset=0&country_id=${navCountry_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&medium_id=${navMedium_id}&search_text=${navSearch}`,
          Auth
        );
        console.log(getTable.data.data);
        setTableData(getTable.data.data);
        setStartUpload(false);
      }
      if (+rowCount.data.data.count === 0) {
        setNoData(true);
        setStartUpload(false);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setStartUpload(false);
    }
    handleStatus();
  }

  async function handleCheckbox(e, row) {
    setStartUpload(true);
    var check = e.target.checked;
    console.log(check);
    tempTableData = tableData.map((object) => {
      return object.achiever_talk_id === row.achiever_talk_id
        ? { ...object, is_enabled: check }
        : { ...object };
    });
    subtitleJson = JSON.stringify(row.subtitle_languages_json);
    setTableData(tempTableData);
    const dateEnbleEdit = {
      country_id: row.country_id,
      title: row.title,
      duration_in_secs: row.duration_in_secs,
      board_id: row.board_id,
      course_id: row.course_id,
      audio_language_id: row.audio_language_id,
      subtitle_languages_json: subtitleJson,
      video_url: row.video_url,
      thumbnail_url: row.thumbnail_url,
      rating: row.rating,
      video_id: row.video_id,
      preview_image_url: row.preview_image_url,
      is_enabled: check,
    };
    console.log(dateEnbleEdit);
    console.log(row.achiever_talk_id);
    try {
      const newAchiever = await Axios.put(
        `${BASE_URL}/achievers/${row.achiever_id}/talks/${row.achiever_talk_id}`,
        dateEnbleEdit,
        Auth
      );
      console.log(newAchiever);
      toast.success("Successfully Edited Achiever Talk");
      setStartUpload(false);
      fetchTable();
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setStartUpload(false);
      fetchTable();
    }
  }

  async function handleDelete(rows) {
    setPopUp(true);
    setDeleteRow(true);
    console.log(123, deleteRow, popUp);
    if (deleteRow === true) {
      console.log(456);
      setStartUpload(true);
      try {
        const deleteRow = await Axios.delete(
          `${BASE_URL}/achievers/${rows.achiever_id}/talks/${rows.achiever_talk_id}`,
          Auth
        );
        console.log(deleteRow.data.data);
        toast.success("Successfully Deleted Row");
        setStartUpload(true);
        fetchTable();
      } catch (error) {
        if (error.response && error.response.data) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
        }
        setStartUpload(false);
      }
      setPopUp(false);
      setDeleteRow(false);
    }
    if (deleteRow === false) {
      rowData = rows;
      console.log(rowData, popUp);
    }
  }

  function okDelete() {
    // setDeleteNode(true);
    handleDelete(rowData);
    // setPopUp(false);
  }

  //function of cancel in popup
  function cancel() {
    setDeleteRow(false);
    setPopUp(false);
  }

  const fetchOtherDataNav = async (name) => {
    setStartUpload(true);
    try {
      if (name === "country") {
        const getBoard = await (
          await Axios.get(
            `${BASE_URL}/master_data/boards?country_id=${navCountry_id}`,
            Auth
          )
        ).data;
        console.log(getBoard);
        setNavbarData((prevState) => ({
          ...prevState,
          allBoards: getBoard.data,
        }));
        setStartUpload(false);
      } else if (name === "board") {
        const getMediums = await (
          await Axios.get(
            `${BASE_URL}/master_data/mediums?country_id=${navCountry_id}`,
            Auth
          )
        ).data;
        console.log(getMediums);
        setNavbarData((prevState) => ({
          ...prevState,
          allMediums: getMediums.data,
        }));
        setStartUpload(false);
      } else if (name === "language") {
        const getCourse = await (
          await Axios.get(
            `${BASE_URL}/master_data/courses?board_id=${navBoard_id}`,
            Auth
          )
        ).data;
        console.log(getCourse);
        setNavbarData((prevState) => ({
          ...prevState,
          allCourses: getCourse.data,
        }));
        setStartUpload(false);
      }
      setStartUpload(false);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
      setStartUpload(false);
    }
  };

  async function handleNavbar(e, selectedOption) {
    if (selectedOption === "country") {
      navCountry_id = e.target.value;
      navBoard_id = -1
      navCourse_id = -1
      navMedium_id = -1
    } else if (selectedOption === "board") {
      navBoard_id = e.target.value;
      navCourse_id = -1
      navMedium_id = -1
    } else if (selectedOption === "language") {
      navMedium_id = e.target.value;
      navCourse_id = -1
    } else if (selectedOption === "course") {
      navCourse_id = e.target.value;
    }
    await fetchOtherDataNav(selectedOption);
    setPageNumber(1);
    setOffSet(0);
    setNoData(false);
    setstate((prevState) => ({
      ...prevState,
      country: navCountry_id,
      board: navBoard_id,
      course: navCourse_id,
      medium: navMedium_id,
    }));
  }

  function handleNavSearch(e) {
    var tempNavSearch = e.target.value;
    setPageNumber(1);
    setOffSet(0);
    setNoData(false);
    setNavSearch(tempNavSearch);
    // fetchTable();
  }

  useEffect(() => {
    fetchTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, navSearch]);

  function paginationClick(number, offSetNumber) {
    setPageNumber(+number);
    setOffSet(+offSetNumber);
    setStartUpload(true);
  }

  return (
    <div>
      <NavbarTalks
        handleNavbar={handleNavbar}
        handleNavSearch={handleNavSearch}
        navbarData={navbarData}
        stateValue={state}
      />
      <div className="container-fluid">
        {startUpload ? (
          <ReactLoading
            type="spin"
            color="#2f80ed"
            className="startUploadLoadSpin"
          />
        ) : null}
        <div className="head2">
          <div>
            <h1 className="h1AchieversNotes">Achievers Talks</h1>
          </div>
          <div className="headButton">
            <button className="statusButton" onClick={handleStatus}>
              {status}
            </button>

            <button className="addAchieverTalksButton" onClick={handleShow}>
              Add Talks
            </button>
          </div>
          <div
            onClick={handleStatus}
            className={userDetails ? "overlay " : "noOverlay "}
          ></div>
          <div className={userDetails ? "allStatusDropdown" : "noDropdown"}>
            <button
              className={
                status === "Enabled" ? "logoutButtonBlue" : "logoutButton"
              }
              onClick={handleEnabled}
            >
              Enabled
            </button>
            <button
              className={
                status === "Disabled" ? "logoutButtonBlue" : "logoutButton"
              }
              onClick={handleDisabled}
            >
              Disabled
            </button>
            <button
              className={status === "All" ? "logoutButtonBlue" : "logoutButton"}
              onClick={handleAll}
            >
              All
            </button>
          </div>
        </div>
        {noData ? (
          <h5 className="loadingTextData">Data is not available.</h5>
        ) : (
          <div>
            <div className="tableContainer">
              <table className="tableAchTalks">
                <thead>
                  <tr>
                    {thead_column.map((el, key) => {
                      return <th key={key}>{el}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {tableData
                    ? tableData.map((rows, key) => {
                        return (
                          <tr key={key}>
                            <td>
                              <div>
                                {/* <button
                              className="editButton"
                              onClick={() => handleEdit(rows)}
                            >
                              <img src={EditIcon} alt="" />
                            </button> */}
                                <button
                                  className="talkDeleteIconBtn"
                                  onClick={() => handleEdit(rows)}
                                >
                                  <EditIcon className="talkDeleteIcon" />
                                </button>
                                <button
                                  className="talkDeleteIconBtn"
                                  onClick={(e) => handleDelete(rows)}
                                >
                                  <DeleteIcon className="talkDeleteIcon" />
                                </button>
                              </div>
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                className="checkbox"
                                id="checkbox"
                                name="checkbox"
                                checked={rows.is_enabled}
                                onChange={(e) => handleCheckbox(e, rows)}
                              />
                            </td>
                            <td>{rows.country_name}</td>
                            <td className="achieversID">
                              {rows.achiever_code}
                            </td>
                            <td className="rowNames">{rows.achiever_name}</td>
                            <td className="date">{rows.updated_date}</td>
                            <td className="titleCaption">{rows.title}</td>
                            <td>{rows.duration_in_secs}</td>
                            <td>{rows.board_display_name}</td>
                            <td>{rows.course_display_name}</td>
                            <td>{rows.audio_language}</td>
                            <td>
                              {Array.isArray(rows.subtitle_languages_json)
                                ? rows.subtitle_languages_json
                                    .map((language) => language.name)
                                    .join(", ")
                                : null}
                            </td>
                            <td>{rows.theme}</td>
                            <td>{rows.rating}</td>
                            <td className="views">{rows.views}</td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>

            {/* <div className="paginationTalks">
            <Stack spacing={2} align="center">
              <Pagination
                count={5}
                color="primary"
                align="center"
                showFirstButton
                showLastButton
              />
            </Stack>
          </div> */}
            <Pagination
              paginationClick={paginationClick}
              numberOfRows={numberOfRows}
              offSet={offSet}
              pageNumber={pageNumber}
            />
          </div>
        )}

        <ModalComponent
          show={show}
          onClick={(event, reason) => {
            if (reason !== "backdropClick") {
              handleClose();
            }
          }}
          onChange={handleChange}
          title={editValue ? "Edit Achievers Talks" : "Add Achievers Talks"}
          className="addAchieverPopup"
        >
          <div className="addTalksForm">
            <Formik
              initialValues={editValue ? rowValues : initialValues}
              // validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ handleChange, setFieldValue }) => {
                return (
                  <div>
                    <Form>
                      <label
                        className={
                          notFilled && country_id === -1
                            ? "lableAchieverRed"
                            : "lableAchiever"
                        }
                      >
                        Country
                      </label>
                      <br />
                      <Field
                        as="select"
                        name="country"
                        onChange={handleChange}
                        className="selectOption"
                        // value={editValue ? rowValues.country : undefined}
                      >
                        <option value="-1">Country</option>
                        {dropDownData.country.map((e, key) => {
                          return (
                            <option value={e.country_id} key={key}>
                              {e.country_name}
                            </option>
                          );
                        })}
                      </Field>
                      <br />
                      <label
                        className={
                          notFilled && board_id === -1
                            ? "lableAchieverRed"
                            : "lableAchiever"
                        }
                      >
                        Board
                      </label>
                      <br />
                      <Field
                        as="select"
                        name="board"
                        onChange={handleChange}
                        className="selectOption"
                        // value={editValue ? rowValues.board : undefined}
                      >
                        <option value="-1">Board</option>
                        {dropDownData.allBoards.map((e, key) => {
                          return (
                            <option value={e.board_id} key={key}>
                              {e.display_name}
                            </option>
                          );
                        })}
                      </Field>
                      <br />
                      <label
                        className={
                          notFilled && medium_id === -1
                            ? "lableAchieverRed"
                            : "lableAchiever"
                        }
                      >
                        Audio Language
                      </label>
                      <br />
                      <Field
                        as="select"
                        name="language"
                        onChange={handleChange}
                        className="selectOption"
                        // value={editValue ? rowValues.language : undefined}
                      >
                        <option value="-1">Audio Language</option>
                        {dropDownData.allMediums.map((e, key) => {
                          return (
                            <option value={e.medium_id} key={key}>
                              {e.medium_name}
                            </option>
                          );
                        })}
                      </Field>
                      <br />
                      <label
                        className={
                          notFilled && course_id === -1
                            ? "lableAchieverRed"
                            : "lableAchiever"
                        }
                      >
                        Course
                      </label>
                      <br />
                      <Field
                        as="select"
                        name="course"
                        onChange={handleChange}
                        className="selectOption"
                        // value={editValue ? rowValues.courses : undefined}
                      >
                        <option value="-1">Course</option>
                        {dropDownData.allCourses.map((e, key) => {
                          return (
                            <option value={e.course_id} key={key}>
                              {e.display_name}
                            </option>
                          );
                        })}
                      </Field>
                      {/* <br />
                    <label className="lableAchiever">Group K12</label>
                    <br />
                    <Field
                      as="select"
                      name="group"
                      onChange={handleChange}
                      className="selectOption"
                    >
                      {groups.map((e, key) => {
                        return <option key={key}>{e}</option>;
                      })}
                    </Field> */}
                      <br />
                      <label className="lableAchiever">Theme</label>
                      <br />
                      <Field
                        as="select"
                        name="theme"
                        onChange={handleChange}
                        className="selectOption"
                        // value={editValue ? rowValues.theme : undefined}
                      >
                        {themes.map((e, key) => {
                          return <option key={key}>{e}</option>;
                        })}
                      </Field>
                      <br />
                      <label className="lableAchiever">Similarity</label>
                      <br />
                      <Field
                        as="select"
                        name="similarity"
                        onChange={handleChange}
                        className="selectOption"
                        // value={editValue ? rowValues.theme : undefined}
                      >
                        {themes.map((e, key) => {
                          return <option key={key}>{e}</option>;
                        })}
                      </Field>
                      <label
                        className={
                          notFilled && achieverCode === ""
                            ? "lableAchieverRed"
                            : "lableAchiever"
                        }
                      >
                        Achiever Code
                      </label>
                      <br />
                      <Field
                        type="text"
                        name="achieverCode"
                        className="input"
                        // value={editValue ? rowValues.title : undefined}
                      />
                      <br />
                      <label
                        className={
                          notFilled && title === ""
                            ? "lableAchieverRed"
                            : "lableAchiever"
                        }
                      >
                        Title
                      </label>
                      <br />
                      <Field
                        type="text"
                        name="title"
                        maxLength="50"
                        className="inputTitle"
                        // value={editValue ? rowValues.title : undefined}
                      />
                      <label className="maxChr">Max 50 character</label>
                      <br />
                      {/* <ErrorMessage name="name" component="div">
                             {(error) => <div className={styles.errors}>{error}</div>}
                            </ErrorMessage> */}

                      <label
                        className={
                          notFilled && selected.length === 0
                            ? "lableAchieverRed"
                            : "lableAchiever"
                        }
                      >
                        Subtitle
                      </label>
                      <br />
                      {/* {editValue ? ( */}

                      {/* <Select
                      isMulti
                      defaultValue={rowValues.subtitle}
                      // value={rowValues.subtitle}
                      options={dropDownData.allLanguages}
                      name="subtitle"
                      onChange={handleSelect}
                      className="selectOption1"
                      components={{
                        Option,
                      }}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                    ></Select> */}

                      <MultiSelect
                        options={dropDownData.allLanguages}
                        value={selected}
                        onChange={setSelected}
                        labelledBy="Select"
                        hasSelectAll={false}
                        className="selectOption1"
                      />

                      {/* ) : ( */}
                      {/* <Select
                        isMulti
                        options={names}
                        onChange={handleSelect}
                        className="selectOption1"
                      ></Select>
                    )} */}
                      {/* <Select isMulti options={editValue?rowValues.subtitle:names} onChange={handleSelect}
                     className="selectOption1"></Select> */}
                      {/* {editValue ? handleEditProp : undefined} */}
                      {/* {editValue ? (
                      <FormControl>
                        <Select
                          multiple
                          // defaultValue={rowValues.subtitle}
                          // value={rowValues.subtitle}
                          value={personName}
                          onChange={handleChangeDropDown}
                          renderValue={(selected) => selected.join(", ")}
                          className="selectOption1"
                        >
                          {names.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                checked={personName.indexOf(name) > -1}
                                className="checkboxDropDown"
                              />
                              <ListItemText
                                primary={name}
                                className="dropdownText"
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : ( */}
                      {/* <FormControl>
                        <Select
                          multiple
                          value={personName}
                          // onChange={(e) => {handleChangeDropDown(e);
                          //   handleDropDown(e);}}
                          onChange={handleChangeDropDown}
                          renderValue={(selected) => selected.join(", ")}
                          className="selectOption1"
                        >
                          {names.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                checked={personName.indexOf(name) > -1}
                                className="checkboxDropDown"
                              />
                              <ListItemText
                                primary={name}
                                className="dropdownText"
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}
                      {/* ) */}
                      {/* } */}
                      {/* <FormControl>
                      <Select
                        multiple
                        value={personName}
                        // onChange={(e) => {handleChangeDropDown(e);
                        //   handleDropDown(e);}}
                          onChange={handleChangeDropDown}
                        renderValue={(selected) => selected.join(", ")}
                        className="selectOption1"
                      >
                        {names.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox checked={personName.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}
                      {/* <Select
                      options={colorOptions}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      // onChange={handleChange}
                      allowSelectAll={true}
                      className="selectOption"
                      defaultValue={editValue ? rowValues.subtitle : ""}
                    /> */}
                      {/* <FormControl>
                      <Select
                        multiple
                        value={editValue ? rowValues.subtitle : personName}
                        onChange={handleChangeSelect}
                        // input={<OutlinedInput label="Name" />}
                        // MenuProps={MenuProps}
                        className="inputTitle"
                      >
                        {names.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, personName, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}
                      {/* <br /> */}
                      <label
                        className={
                          notFilled && videoLink === ""
                            ? "lableAchieverRed"
                            : "lableAchiever"
                        }
                      >
                        Video Link
                      </label>
                      <br />
                      <Field type="text" name="videoLink" className="input" />
                      <br />
                      <label
                        className={
                          notFilled && videoId === ""
                            ? "lableAchieverRed"
                            : "lableAchiever"
                        }
                      >
                        Video Id
                      </label>
                      <br />
                      <Field type="text" name="videoId" className="input" />
                      <br />

                      {/* <label className="lableAchiever">Achievers Id</label>
                    <br />
                    <Field
                      type="text"
                      name="achieversId"
                      className="input"
                      value={editValue ? rowValues.achieversId : undefined}
                    />
                    <br /> */}

                      <label
                        className={
                          notFilled && duration === ""
                            ? "lableAchieverRed"
                            : "lableAchiever"
                        }
                      >
                        Duration
                      </label>
                      <br />
                      <Field
                        type="number"
                        min="0"
                        name="duration"
                        className="input"
                        // value={editValue ? rowValues.duration : undefined}
                      />
                      <br />
                      <label
                        className={
                          notFilled && rating === ""
                            ? "lableAchieverRed"
                            : "lableAchiever"
                        }
                      >
                        Rating
                      </label>
                      <br />
                      <Field
                        type="number"
                        max="5"
                        min="0"
                        placeholder="Enter number 0-5"
                        name="rating"
                        className="input"
                        // value={editValue ? rowValues.rating : undefined}
                      />
                      {/* <label
                        className={
                          notFilled && sourcePreviewImage === ""
                            ? "lableAchieverRed"
                            : "lableAchiever"
                        }
                      >
                        Preview Image
                      </label>
                      <br />
                      <label
                        htmlFor="previewImage"
                        className="talkInputAttach "
                      >
                        Attach Preview Image
                      </label>
                      <Field
                        type="file"
                        name="previewImage"
                        className="insideText"
                        accept=".jpg, .png, .jpeg"
                        disabled={editValue}
                        // onChange={uploadFile}
                      />
                      {attachedFile !== "" ? (
                        <div>
                          <p className="fileName">{attachedFile.name}</p>
                          <div className="notesUploadDiv">
                            <button
                              type="button"
                              onClick={uploadPreviewFile}
                              className="btnUpload"
                              disabled={uploaded}
                            >
                              Upload
                            </button>
                            <div>
                              {upload ? (
                                <div className="noteLoadingDiv">
                                  <ReactLoading
                                    type="spinningBubbles"
                                    color="#2f80ed"
                                    className="uploadLoadSpin"
                                  />
                                  <p>Uploading...</p>
                                </div>
                              ) : null}
                              {uploaded ? <p>File Uploaded</p> : null}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )} */}
                      <label
                        className={
                          notFilled && sourceFilePathThumbnail === ""
                            ? "lableAchieverRed"
                            : "lableAchiever"
                        }
                      >
                        Thumbnail Image
                      </label>
                      <br />
                      <label htmlFor="attachFIle" className="talkThumbnail">
                        Attach thumbnail
                      </label>
                      <div
                        className={
                          // attachedFile === ""
                          // ? "talkThumbnailHiddenTextBefore"
                          // :
                          attThumbnailFile !== ""
                            ? "talkThumbnailHiddenTextAfter"
                            : "talkThumbnailHiddenText"
                        }
                      >
                        <img
                          src={
                            editValue
                              ? rowValues.thumbnailURL
                              : pic
                              ? URL.createObjectURL(pic)
                              : null
                          }
                          alt={pic ? pic.name : null}
                          className={
                            editValue
                              ? "talkPreviewImg"
                              : pic
                              ? "talkPreviewImg"
                              : ""
                          }
                        />
                        <input
                          id="thumbnailFile"
                          name="thumbnailFile"
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={(event) => {
                            setFieldValue("file", event.currentTarget.files[0]);
                            setPic(event.currentTarget.files[0]);
                            if (event.currentTarget.files[0]) {
                              var newURL = URL.createObjectURL(
                                event.currentTarget.files[0]
                              );
                              setRowValues({
                                ...rowValues,
                                thumbnailURL: newURL,
                              });
                            }
                          }}
                          className="talkThumbnailbutton"
                          // disabled={attThumbnailFile !== "" ? false : true}
                        />
                        {pic ? (
                          <button
                            name="removeFile"
                            type="button"
                            onClick={() => {
                              sourceFilePathThumbnail = "";
                              setPic("");
                              setAttThumbnailFile("");
                              setUploadThumbnail(false);
                              setUploadedThumbnail(false);
                              setRowValues({
                                ...rowValues,
                                thumbnailURL: null,
                              });
                            }}
                            className="talkThumbnailremoveButton"
                          >
                            Remove{" "}
                          </button>
                        ) : null}
                      </div>
                      {attThumbnailFile !== "" ? (
                        <div>
                          <p className="fileName">{attThumbnailFile.name}</p>
                          <div className="notesUploadDiv">
                            <button
                              type="button"
                              onClick={uploadThumbnailFile}
                              className="btnUpload"
                              disabled={uploadedThumbnail}
                            >
                              Upload
                            </button>
                            <div>
                              {uploadThumbnail ? (
                                <div className="noteLoadingDiv">
                                  <ReactLoading
                                    type="spinningBubbles"
                                    color="#2f80ed"
                                    className="uploadLoadSpin"
                                  />
                                  <p>Uploading...</p>
                                </div>
                              ) : null}
                              {uploadedThumbnail ? <p>File Uploaded</p> : null}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <hr />

                      <Box component="div" className="footerAddAchiever">
                        <button
                          onClick={handleClose}
                          className="btnCancel"
                          type="button"
                        >
                          <div>Cancel</div>
                        </button>

                        <button
                          type="submit"
                          // onClick={onSubmit}
                          className="btnSave"
                        >
                          <div>Save</div>
                        </button>
                      </Box>
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </div>
        </ModalComponent>
        <div onClick={cancel} className={popUp ? "popUpBack" : "noPopUp"}>
          <div className={popUp ? "yesPopUp" : "noPopUp"}>
            <button onClick={cancel} className="popUpCloseBtn">
              <CloseOutlinedIcon className="closeIcon" />
            </button>
            <div className="warningDiv">
              <ReportOutlinedIcon className="warningIcon" />
            </div>
            <h2 className="popUph2">Are You Sure?</h2>
            <div className="popUpButtonDiv">
              <button onClick={cancel} className="popUpCancelButton">
                Cancel
              </button>
              <button onClick={okDelete} className="popUpOkButton">
                OK
              </button>
            </div>
          </div>
        </div>
        <ToastContainer
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
}

export default AchieversTalks;
