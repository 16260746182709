import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import { Redirect, Link } from "react-router-dom";
import LogoApps from "../../../assets/images/LogoApps.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../config/Api";
import Axios from "axios";

const initialValues = {
  passwordOne: "",
  passwordTwo: "",
};
const validationSchema = Yup.object({
  passwordOne: Yup.string().required("Password is required"),
  passwordTwo: Yup.string().required("Password is required"),
  // .oneOf([Yup.ref("passwordOne"), null], "Password Must Match")
  // .required("Re-enter the Password"),
});

function ResetPassword(props) {
  const [login, setLogin] = useState(false);
  const [notEquall, setNotEquall] = useState(false);

  let user_id = props.match.params.user_id;

  console.log(props.match.params.user_id);

  const onSubmit = async (values) => {
    if (values.passwordOne === values.passwordTwo) {
      setNotEquall(false);
      await Axios.post(`${BASE_URL}/login/reset_password`, {
        user_id: user_id,
        password: values.passwordOne,
      })
        .then((response) => {
          console.log(response);
          toast.success("New Password Created Successfully");
          setTimeout(() => {
            // return (window.location = "/");
            setLogin(true);
          }, 3000);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.data) {
            console.log(err.response.data.message);
            toast.error(err.response.data.message);
          }
          setTimeout(() => {
            return (window.location = "/");
          }, 3000);
        });
    }
    if (values.passwordOne !== values.passwordTwo) {
      setNotEquall(true);
      toast.error("Password Must Match");
    }
  };

  console.log(login);
  if (login) {
    return <Redirect from="/" to="/" />;
  }

  return (
    <div>
      <div className="logo">
        <Link className="logoBtn" to="/">
          <img src={LogoApps} alt="Logo-Apppsndevices" />
        </Link>
      </div>
      <div>
        <hr className="line"></hr>
      </div>
      <div className="loginContent">
        <div className="loginTitle">
          <h1 className="h1Reset">Reset Password</h1>
        </div>

        <div className="formDiv">
          <Box component="div">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {(formik) => {
                return (
                  <div>
                    <Form>
                      <Box component="div" className="username">
                        <Field
                          type="password"
                          id="passwordOne"
                          name="passwordOne"
                          placeholder="Password"
                          className="username_field"
                        />
                      </Box>
                      <ErrorMessage name="passwordOne" component="div">
                        {(error) => <div className="error">{error}</div>}
                      </ErrorMessage>
                      <Box component="div" className="password">
                        <Field
                          type="password"
                          id="passwordTwo"
                          name="passwordTwo"
                          placeholder="Re-enter Password"
                          className="password_field"
                        />
                      </Box>
                      <ErrorMessage name="passwordTwo" component="div">
                        {(error) => <div className="error">{error}</div>}
                      </ErrorMessage>
                      {notEquall ? (
                        <p className="notMatching">Password not Matching</p>
                      ) : (
                        ""
                      )}
                      <button type="submit" className="btn">
                        <div className="btnText">Submit</div>
                      </button>
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </Box>
        </div>
      </div>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <p className="developer">Developed by Sagar Bazar</p>
    </div>
  );
}

export default ResetPassword;
